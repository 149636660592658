<template>
	<div>
		<div class="fixed-top hide-desktop">
			<div class="header flex row space container">
				<header-logo />
			</div>
		</div>
		<div :class="['content', { 'full-width-desktop': fullWidthDesktop }]"><slot /></div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import HeaderLogo from '@/components/HeaderLogo.vue'

export default {
	name: 'LogoOnlyLayout',
	components: { HeaderLogo },
	computed: {
		...mapState(useModalStore, ['balanceNotification']),
		fullWidthDesktop() {
			return this.$route.meta.fullWidthDesktop
		}
	}
}
</script>

<style scoped lang="scss">
.content {
	@media (min-width: $screen-desktop) {
		max-width: 444px;
		padding-left: 0;
		&.full-width-desktop {
			max-width: 838px;
			padding-bottom: 0;
		}
		&::v-deep .error-page {
			@media (min-width: $screen-desktop) {
				height: unset;
				padding-left: 240px;
			}
		}
	}
}
</style>
