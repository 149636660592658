<template>
	<div class="dialog-post-wrap">
		<div class="post-overlay" @click="closeDetailPage" />
		<div class="dialog-post" :style="stylePopUp">
			<post-component
				v-if="isReady"
				:id="id"
				:key="id"
				:background="false"
				:attachments="attachments"
				:author="author"
				:total_likes="total_likes"
				:total_comments="total_comments"
				:total_consumer_likes="total_consumer_likes"
				:published_text="published_text"
				:description="description"
				:audience="audience"
				:hashtags="hashtags"
				:mentioned="mentioned"
				:visibility="visibility"
				:publication_status="publication_status"
				:girl_liked_any_comment="girl_liked_any_comment"
				:detail-page="true"
				@videoLoaded="onVideoLoaded"
				:on-click-nickname-function="getClickNickNameFunction(author.nickname)"
				@load="onLoad"
				ref="postComponent"
			>
				<template #comments>
					<div class="post-comments-wrap">
						<div class="post-comments" ref="commentsList">
							<div class="post-line" />
							<div v-if="commentsLoaded" class="post-info">
								<div class="post-total-comments">
									{{ comments.meta.total > 0 ? 'Комментарии:' : 'Нет комментариев' }}
								</div>
								<div v-if="showLikeButton && comments.meta.total > 0" class="post-info-choose">Выбери лучший</div>
							</div>
							<div v-else class="pagination-loader">
								<i class="i-spinner rotating" />
							</div>
							<infinite-scroll :load-function="getMorePostComments" :target-element-selector="targetSelector">
								<comment-component
									v-for="comment in comments.data"
									:key="`comment${comment.id}`"
									:preview-letters-count="70"
									:author-id="comment.comment.author.id"
									:nickname="comment.comment.author.nickname"
									:avatar="comment.comment.author.avatar"
									:has-girl-subscription="comment.comment.author?.has_girl_subscription"
									:text="comment.comment.text"
									:created-at="comment.comment.created_at"
									:liked-at-text="comment.comment.liked_time_as_text"
									:liked-avatar="comment.post.author.avatar"
									:on-click-nickname-function="getClickNickNameFunction(comment.comment.author.nickname)"
									:mentioned="comment.comment.mentioned"
									:ref="`#comment-${comment.id}`"
									:show-liked-post="false"
								>
									<template #append v-if="showLikeButton">
										<span class="like-icon sm" @click="setLikeToComment(comment.id)" />
									</template>
								</comment-component>
							</infinite-scroll>
						</div>
					</div>
				</template>
			</post-component>
			<placeholder-post v-else />
			<comment-field v-if="isConsumer || isGuest" ref="commentField" @comment-created="scrollToTop" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { usePostStore } from '@/stores/post'
import { useGirlProfileStore } from '@/stores/girlProfile'
import PostComponent from '@/components/PostComponent.vue'
import CommentComponent from '@/components/CommentComponent.vue'
import InfiniteScroll from '@/components/InfiniteScroll.vue'
import CommentField from '@/components/CommentField.vue'
import PlaceholderPost from '@/components/placeholders/PlaceholderPost.vue'
import ModalState from '@/helpers/modalState'

const modalState = new ModalState()
const sizePostInfo = 382

export default {
	name: 'PostView',
	components: {
		PlaceholderPost,
		CommentField,
		InfiniteScroll,
		CommentComponent,
		PostComponent
	},
	watch: {
		async $route() {
			this.closeDetailPage()
		}
	},
	data() {
		return {
			videoLoaded: false,
			needToScroll: '',
			contentLoaded: false
		}
	},
	computed: {
		...mapWritableState(usePostStore, ['girl_liked_any_comment']),
		...mapState(usePostStore, [
			'id',
			'author',
			'comments',
			'hashtags',
			'mentioned',
			'description',
			'total_likes',
			'attachments',
			'audience',
			'published_text',
			'total_consumer_likes',
			'isLoading',
			'isReady',
			'visibility',
			'total_comments',
			'publication_status',
			'commentsLoaded'
		]),
		...mapState(useModalStore, ['popUpSizeWidth', 'popUpSizeHeight']),
		...mapState(useGirlProfileStore, { currentUserId: 'id' }),
		isConsumer() {
			return this.$auth.check('consumer')
		},
		isGirl() {
			return this.$auth.check('girl')
		},
		isGuest() {
			return JSON.parse(this.$auth.remember())?.role === 'guest'
		},
		isOwnProfile() {
			return this.currentUserId === this.author.id
		},
		showLikeButton() {
			return this.isGirl && this.isOwnProfile && !this.girl_liked_any_comment
		},
		targetSelector() {
			if (window.screen.width > 980) {
				return '.post-about'
			}
			return '.dialog-post'
		},
		stylePopUp() {
			if (document.documentElement.clientWidth > 980) {
				return {
					width: `${this.popUpSizeWidth}px`,
					height: `${this.popUpSizeHeight}px`
				}
			}
			return ''
		}
	},
	beforeDestroy() {
		this.removeCommentsFromState()
		modalState.closeModal(this.$el)
	},
	mounted() {
		const { hash } = window.location
		modalState.openModal(this.$el, window.scrollY)
		this.getPostComments(this.id)
		if (hash === '#commentsList') {
			if (this.attachments.type !== 'video') {
				this.$refs.commentsList.scrollIntoView()
			} else {
				this.needToScroll = 'commentsList'
			}
			window.history.replaceState('', '', window.location.pathname)
		}
		this.scrollToComment()
	},
	methods: {
		...mapActions(useModalStore, { closeDetailPage: 'closeDetailPage', setResizePopUp: 'setResizePopUp' }),
		...mapActions(usePostStore, { getPopUpSize: 'getPopUpSize', removeCommentsFromState: 'removeCommentsFromState' }),
		getClickNickNameFunction(nickname) {
			if (!this.isGirl) {
				return () => this.pasteNickname(nickname)
			}
			return undefined
		},
		moveToScrollCommentList() {
			this.needToScroll = ''
			if (this.$refs.commentsList) {
				this.$refs.commentsList.scrollIntoView()
			}
		},
		onVideoLoaded() {
			if (this.attachments.type === 'video' && this.needToScroll === 'commentsList') {
				this.moveToScrollCommentList()
			}
			this.onLoad()
		},
		onLoad() {
			this.setResizePopUp(this.$refs.postComponent.getNaturalSize(), sizePostInfo)
			this.contentLoaded = true
		},
		pasteNickname(nickname) {
			this.$refs.commentField.pasteSubstring(`@${nickname} `)
		},
		...mapActions(usePostStore, ['getPost', 'sendComment', 'getPostComments', 'getMorePostComments', 'likeComment']),
		scrollToTop() {
			this.$refs.commentsList.scrollIntoView()
			this.$refs.commentsList.scrollTo(0, 10)
		},
		setLikeToComment(commentId) {
			this.girl_liked_any_comment = true
			this.likeComment(commentId, this.id)
		},
		scrollToComment() {
			const { hash } = window.location
			const ref = this.$refs[hash]

			if (hash && ref) {
				const targetComment = this.$refs[hash][0].$el
				targetComment.scrollIntoView()
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.post-info {
	display: flex;
	justify-content: space-between;

	&-choose {
		color: #f81a5f;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
	}
}

.dialog-post::v-deep {
	position: fixed;
	top: 0;
	left: 0;
	background: #191c22;
	z-index: 999;
	height: 100%;
	width: 100%;
	padding: 20px;
	padding-bottom: 50px;
	overflow: scroll;
	@media (min-width: $screen-desktop) {
		width: unset;
		max-height: calc(100% - 80px);
		max-width: calc(100% - 80px);
		min-width: 907px;
		min-height: 310px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 16px;
		padding: 0;
		overflow: visible;
	}
	& .post {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		& .post-options-button {
			margin-right: 32px;
		}
		@media (min-width: $screen-desktop) {
			flex-direction: row;
			height: 100%;
			max-width: unset;
			padding: 0;
			margin-bottom: 0;
		}
	}
}

::v-deep {
	.dialog-close-btn {
		top: 3px;
		background: transparent;
		padding: 0;
		border: none;
		color: #727281;
		font-size: 24px;
		cursor: pointer;
		display: flex;
		position: absolute;
		right: 16px;
	}
}

@media (min-width: $screen-desktop) {
	.post-overlay::v-deep {
		position: fixed;
		z-index: 998;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.8);
		&:hover + .dialog-post {
			& .dialog-close-btn {
				color: #ffffff;
			}
			&:active + .dialog-post {
				& .dialog-close-btn {
					color: #545460;
				}
			}
		}
	}
	::v-deep {
		.comment-input-wrap {
			max-width: 382px;
			margin-right: 0;
			padding: 24px;
			margin-left: auto;
			border-radius: 0 0 16px 0;
		}
		.dialog-close-btn {
			right: -24px;
			top: -24px;
			.i-cross {
				transition: $transition-color;
			}
			:hover {
				color: #ffffff;
			}
			:active {
				color: #545460;
			}
		}
	}
	::v-deep {
		.comment-input-wrap {
			max-width: 382px;
			margin-right: 0;
			padding: 24px;
			margin-left: auto;
			border-radius: 0 0 16px 0;
		}
		.dialog-close-btn {
			right: -24px;
			top: -24px;
			.i-cross {
				transition: $transition-color;
			}
			:hover {
				color: #ffffff;
			}
		}
	}
}
</style>
