import Home from '@/views/HomeView.vue'
import AppConfig from '@/config.class'

const routes = [
	{
		name: 'home',
		path: '/',
		component: Home,
		meta: {
			auth: AppConfig.guestAccessAuth,
			layout: 'home',
			title: 'Лента'
		}
	},
	{
		path: '',
		component: () => import('@/views/Messenger/MessengerView.vue'),
		meta: {
			auth: {
				roles: ['girl', 'consumer'],
				forbiddenRedirect: { name: 'login' }
			}
		},
		children: [
			{
				name: 'chat',
				path: 'chat',
				component: () => import('@/views/Messenger/MessengerPrivatesList.vue'),
				meta: { layout: 'empty', title: 'Сообщения' }
			},
			{
				name: 'private',
				path: '/chat/:id?',
				component: () => import('@/views/Messenger/MessengerPrivate.vue'),
				meta: { layout: 'empty' }
			}
		]
	},
	{
		name: 'post',
		path: '/post/:id',
		component: () => import('@/views/PostViewDetailPage.vue'),
		meta: {
			auth: true,
			layout: 'arrowBack',
			titleLeft: true,
			title: 'Назад'
		}
	},
	{
		path: '/search',
		component: () => import('@/views/Search/Index.vue'),
		children: [
			{
				name: 'search',
				path: '',
				component: () => import('@/views/Search/SearchView.vue'),
				meta: {
					title: 'Поиск'
				}
			},
			{
				name: 'search-posts',
				path: 'posts',
				component: () => import('@/views/Search/PostsView.vue'),
				meta: {
					layout: 'balanceWithMenu'
				}
			},
			{
				name: 'recommended-posts',
				path: 'recommended-posts',
				component: () => import('@/views/Search/RecommendedPostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Рекомендации'
				}
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/LoginView.vue'),
		meta: {
			auth: false,
			layout: 'empty'
		}
	},
	{
		path: '/post/:id/likes',
		name: 'post-likes',
		component: () => import('@/views/ConsumersWithLikesView.vue'),
		meta: {
			auth: true,
			layout: 'arrowBack',
			title: 'Нравится'
		}
	},
	{
		path: '/sign-in-as-test-user',
		name: 'sign-in-as-test-user',
		component: () => import('@/views/SignInAsTestUser.vue'),
		meta: {
			auth: undefined,
			layout: 'empty'
		}
	},
	{
		path: '/eula',
		name: 'eula',
		component: () => import('@/views/InfoPageView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Пользовательское соглашение',
			slug: 'eula'
		}
	},
	{
		path: '/rules',
		name: 'rules',
		component: () => import('@/views/InfoPageView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Правила',
			slug: 'rules'
		}
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: () => import('@/views/InfoPageView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Политика конфиденциальности',
			slug: 'privacy-policy'
		}
	},
	{
		path: '/terms-of-service',
		name: 'terms-of-service',
		component: () => import('@/views/InfoPageView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Соглашение с автором',
			slug: 'terms-of-service'
		}
	},
	{
		path: '/author_rules',
		name: 'author-rules',
		component: () => import('@/views/InfoPageView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Правила подписки на Автора',
			slug: 'author_rules'
		}
	},
	{
		path: '/tariffs',
		name: 'tariffs',
		component: () => import('@/views/InfoPageView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Тарифы',
			slug: 'tariffs'
		}
	},
	{
		path: '/public_offer',
		name: 'public-offer',
		component: () => import('@/views/PublicOfferView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Оферта о договоре с автором'
		}
	},
	{
		path: '/legal',
		name: 'legal',
		component: () => import('@/views/LegalView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Правила услуги'
		}
	},
	{
		path: '/',
		name: 'service-unavailable',
		component: () => import('@/views/ErrorServiceUnavailable.vue'),
		meta: {
			layout: 'logoOnly'
		}
	},
	{
		path: '/start',
		name: 'start',
		component: () => import('@/views/Girl/StartView.vue'),
		meta: {
			layout: 'logoOnly',
			hideSidebar: true,
			fullWidthDesktop: true
		},
		beforeEnter: (to, from, next) => {
			if (import.meta.env.VITE_DISABLED_REG_FOR_AUTHOR === 'true') {
				next({ path: '/' })
			}
			next()
		}
	},
	{
		path: '*',
		name: 'error',
		component: () => import('@/views/ErrorView.vue'),
		props: true,
		meta: {
			layout: 'logoOnly',
			fullWidthDesktop: true
		}
	}
]

export default routes
