<template>
	<div class="btn-wrap">
		<button
			type="button"
			@click="onClick"
			:class="[btnClass, { invert: isSubscribed }]"
			:disabled="isWaitingSubscriptionResult"
		>
			<template v-if="isWaitingSubscriptionResult">
				<div class="i-spinner post-subscribe-spinner rotating" />
			</template>
			<template v-else>
				{{ subscribedText }}
			</template>
		</button>
		<confirmation-dialog
			v-if="dialogVisible"
			btn-accept-text="Да, отписаться"
			btn-decline-text="Нет, передумал"
			@accept="onUnsubscribe"
			@decline="hideDialog"
			@close="hideDialog"
		>
			<template #title>Вы хотите отписаться?</template>
			Тогда вы не сможете видеть новые посты модели и не будете получать взаимность
		</confirmation-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useHomeStore } from '@/stores/home'
import { useConsumerStore } from '@/stores/consumer'
import { useSearchStore } from '@/stores/search'
import { useProfileStore } from '@/stores/profile'
import { toDayAndMonth } from '@/helpers/dateFormat'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue'
import { useSubscribeStore } from '@/stores/subscribe'

export default {
	name: 'SubscribeButton',
	components: { ConfirmationDialog },
	props: {
		id: { type: Number, default: 0 },
		isGirlProfile: { type: Boolean, default: false },
		isSubscribed: { type: Boolean, default: false },
		isWaitingSubscriptionResult: { type: Boolean, default: false },
		isSubscribeRenewable: { type: Boolean, required: true },
		subscribedTill: { type: [String, null], default: null },
		btnClass: { type: String, default: '' },
		avatar: { type: String, default: '' }
	},
	data() {
		return {
			dialogVisible: false
		}
	},
	computed: {
		...mapState(useProfileStore, {
			consumerId: 'id',
			subscriptionsData: 'subscriptionsData'
		}),
		subscribeLink() {
			const host = this.$config.apiUrl
			return `${host}consumers/${this.consumerId}/girls/${this.id}/subscribe`
		},
		subscribedText() {
			if (!this.isSubscribed) {
				if (
					this.$config.showSubscriptionPriceInGirlProfile &&
					this.$config.girlSubscriptionPrice &&
					this.isGirlProfile
				) {
					return `Подписаться за ${this.$config.girlSubscriptionPrice} руб. в месяц`
				}
				return `Подписаться`
			}
			if (this.isSubscribeRenewable) {
				return 'Вы подписаны'
			}
			return `Действует до ${toDayAndMonth(this.subscribedTill)}`
		},
		isGuest() {
			return JSON.parse(this.$auth.remember())?.role === 'guest'
		}
	},
	methods: {
		...mapActions(useProfileStore, ['getFollowingGirls']),
		...mapActions(useGirlStore, {
			removeBlurFromGirlPosts: 'removeBlurFromPosts',
			applyBlurToGirlPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForGirlPost: 'turnOnWaitingSubscription',
			subscribe: 'subscribe',
			unsubscribe: 'unsubscribe'
		}),
		...mapActions(useHomeStore, {
			resetFilter: 'resetFilter',
			removeBlurFromHomePosts: 'removeBlurFromPosts',
			applyBlurToHomePostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForHomePost: 'turnOnWaitingSubscription'
		}),
		...mapActions(useConsumerStore, {
			removeBlurFromConsumerPosts: 'removeBlurFromPosts',
			applyBlurToConsumerPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForConsumerPost: 'turnOnWaitingSubscription'
		}),
		...mapActions(useSearchStore, {
			removeBlurFromSearchPosts: 'removeBlurFromPosts',
			applyBlurToSearchPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForSearchPost: 'turnOnWaitingSubscription'
		}),
		...mapActions(useSubscribeStore, ['checkSubscribeLimitState']),
		showDialog() {
			this.dialogVisible = true
		},
		hideDialog() {
			this.dialogVisible = false
		},
		onClick() {
			if (this.isGuest) {
				this.$router.push({ name: 'login' }).catch(() => {})
				return
			}
			if (!this.isSubscribeRenewable) {
				return
			}
			if (this.isSubscribed) {
				this.showDialog()
				return
			}
			if (this.checkSubscribeLimitState(this.avatar)) {
				return
			}

			window.location.href = this.subscribeLink
		},
		async onUnsubscribe() {
			await this.unsubscribe(this.id)
			this.applyBlurToGirlPostsOnlyForFans(this.id)
			this.applyBlurToHomePostsOnlyForFans(this.id)
			this.applyBlurToConsumerPostsOnlyForFans(this.id)
			this.applyBlurToSearchPostsOnlyForFans(this.id)
			this.turnOnWaitingSubscriptionForGirlPost(this.id)
			this.turnOnWaitingSubscriptionForHomePost(this.id)
			this.turnOnWaitingSubscriptionForConsumerPost(this.id)
			this.turnOnWaitingSubscriptionForSearchPost(this.id)
			await this.getFollowingGirls()
			await this.resetFilter()
			this.hideDialog()
		}
	}
}
</script>
