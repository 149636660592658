import { defineStore } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { useProfileStore } from '@/stores/profile'

// ToDo перенести логику редиректа на подписку
export const useSubscribeStore = defineStore('subscribe', {
	state: () => ({
		profileStore: useProfileStore(),
		modalStore: useModalStore()
	}),
	actions: {
		checkSubscribeLimitState(avatar = null) {
			if (this.$config.subsModelLimit) {
				if (this.profileStore.subscriptionsData?.data?.girls?.length >= this.$config.subsModelLimit) {
					this.modalStore.openSubLimitModal(avatar)
					return true
				}
			}
			return false
		}
	}
})
